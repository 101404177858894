module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wp.ensys.com.ro/graphql","verbose":true,"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"schema":{"perPage":500,"requestConcurrency":15,"previewRequestConcurrency":5,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","ro"],"defaultLanguage":"ro","prefixDefault":false,"siteUrl":"https://ensys.eu","trailingSlash":"always","i18nextOptions":{"fallbackLng":"ro","interpolation":{"escapeValue":false},"nsSeparator":false},"pages":[{"matchPath":"/preview","languages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ensys","short_name":"Ensys","icon":"content/assets/ensys-icon.png","icons":[{"src":"content/assets/ensys-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"content/assets/ensys-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"content/assets/ensys-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"content/assets/ensys-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"content/assets/ensys-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"content/assets/ensys-icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"content/assets/ensys-icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"content/assets/ensys-icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"content/assets/logo_on_white.png","sizes":"629x217","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f8b52e74bca79706865c0b0723ca99df"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"facebookPixel":{"pixelId":"842347693679573"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-58ZM4PC9","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-addsocialshare-share/gatsby-browser.js'),
      options: {"plugins":[],"size":36,"providers":{"facebook":"Facebook","linkedin":"Linkedin"},"interfacetype":"floating","topoffset":"25%","id":".ass_interface","alignment_desktop":"left","alignment_mobile":"hide"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
